import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Services/ServicesHome_3";
import HeroSection from "../components/Home/HeroVideo";
import Paletacolor from "../components/global/Paletacolor";
import BlockPrincipal from "../components/block/Block_4";
import BlockSecondary from "../components/block/Block_2";
import BlockAbout from "../components/block/Block_3";
import VideoPromocional from "../components/global/VideoPromocional";
import ContentServices from "../components/Services/ContentServices";
import GalleryContent from "../components/Gallery/GalleryContent";
import Values from "../components/values/Values_2";
// import Videos from "../components/global/Videos";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        {/* <div class="elfsight-app-0b796db8-48c7-401b-8b17-435f942edd8a" data-elfsight-app-lazy></div> */}
        <Modal />

        <HeroSection
          urlVideo="https://firebasestorage.googleapis.com/v0/b/archivos-multimedia.appspot.com/o/San%20Francisco%20Stock.mp4?alt=media&token=27de3584-3ed2-4fa1-ac32-2d93e92804d0"
          img1={rpdata?.stock?.[0]}
          img2={rpdata?.stock?.[1]}
          img3={rpdata?.stock?.[2]}
          img4={rpdata?.stock?.[3]}
          slogan={rpdata?.dbSlogan?.[0]?.slogan}
          description={rpdata?.dbValues?.[0].description}
        />
        
        <BlockPrincipal
          text={rpdata?.dbHome?.[0].text}
          image1={rpdata?.stock?.[0]}
          image2={rpdata?.stock?.[1]}
          image3={rpdata?.stock?.[2]}
          sloganPrincipal={true}
          listServices={true}
        />
        <h2 className="text-center">Posts Advertising</h2>
        <div className="w-4/5 h-auto p-5 flex flex-col md:flex-row justify-center items-center gap-5 mx-auto">
          <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/Arriaza's%20Cleaning%20Inc-post%2FArriaza's%20Cleaning%20Inc%20-%20Octubre%20(002).jpg?alt=media&token=e44f7d2c-be94-4969-af80-a42f47c0f7b9" alt="Post-1" className="w-1/3 h-auto object-cover" />
          <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/Arriaza's%20Cleaning%20Inc-post%2FArriaza's%20Cleaning%20Inc%20-%20Thankgiving%20(002).jpg?alt=media&token=27d45c16-fedf-4a93-97e7-bc2dcc944ed7" alt="Post-2" className="w-1/3 h-auto object-cover" />
          <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/Arriaza's%20Cleaning%20Inc-post%2FArriaza's%20Cleaning%20Inc%20-Navidad%20(002).jpg?alt=media&token=b8c315ee-8054-4c81-83b9-7428ae059bf9" alt="Post-3" className="w-1/3 h-auto object-cover" />
        </div>
        <Values
          img1={rpdata?.stock?.[1]}
        />
        <Directories />
        <CounterUp image={rpdata?.stock?.[5]} />

        <BlockSecondary
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image={rpdata?.stock?.[4]}
        />
        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
            />
            : null
        }

        {/* <Videos/> */}

        <BlockAbout
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.stock?.[3]}
          listsAbout={true}
        />

        {/* our reviews */}
        {
          rpdata?.reviews?.isHomeOnly === true ?
            <div className="w-4/5 mx-auto py-[100px]">
              <h1 className="pb-10 text-center">{rpdata?.labels?.general?.titleReviews}</h1>
              <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
            </div>
            : null
        }

        {/* Servicios del home */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-20">
              <ContentServices />
            </div>
            :
            <ServicesHome />

        }

        {/* Gallery cuando es OnePages */}

        {/* Paleta de Colores */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-10 pb-28">
              <h2 className="text-center pb-[50px]">{rpdata?.labels?.general?.titleGallery}</h2>
              <GalleryContent galleryImages={rpdata?.gallery?.length > 0 ? rpdata?.gallery : rpdata?.gallery} />
            </div>
            :
            null

        }
        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.stock?.[8]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>

        <Map />

      </div>
    </BaseLayout>
  );
}

export default Home;
